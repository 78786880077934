import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import TokenSymbol from "../../../../../components/TokenSymbol";
import { PositionType } from "../../../../../../types/Position";
import moment from "moment/moment";
import { Link } from "../../../../../components/Link";

export function UnlockDate({ position }: { position: PositionType }) {
  const isMature = moment().unix() > Number(position.created) + Number(position.lockTime);

  return (
    <>
      {isMature ? (
        <div className="text-sm text-red-500">
          Mature
        </div>
      ) : (
        <div className="text-sm text-green-500">
          {moment(position.created + position.lockTime, "X").format("ll")}
        </div>
      )}
    </>
  );
}

function StakedPositionRow({ position }: { position: PositionType }) {
  if (!position.tokenId) {
    return <div>TokenId not found</div>;
  }

  return (
    <Link
      to={"/#/mobile/positions/" + position.token.address + "/" + position.tokenId}
      className="block mt-3 grid grid-cols-3 bg-bomb-asset-row rounded
      px-2 py-1 transition-colors hover:bg-bomb-asset-row-hover"
    >
      <div className="flex items-center justify-start col-span-2">
        <div>
          <TokenSymbol address={position.token.address} className="w-12 h-12 inline" />
        </div>
        <div className="text-left ml-3 whitespace-nowrap">
          <div className="font-semibold">
            {position.token.shortName}
            &nbsp;
            <span className="text-sm bg-gray-800 text-gray-500 px-2 py-1 rounded-full">
              {(position.lockTime / 86400).toFixed(0)} Day Stake
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <div className="text-left font-bold">
          <div className="text-gray-500 text-sm font-semibold">Amount ({position.token.shortName})</div>
          <div className="text-gray-300 text-sm font-semibold">{position.amount.toFixed(6)}</div>
          <UnlockDate position={position} />
        </div>
        <div className="text-[#DFCC75] ml-2 text-3xl">
          &gt;
        </div>
      </div>
    </Link>
  );
}

export default StakedPositionRow;
