import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import backArrow from "../../../../assets/images/ic_arrow_back.svg";
import visaMC from "../../../../assets/images/visa-mastercard.png";
import { useNavigate, useParams } from "react-router-dom";
import { PositionType } from "../../../../../types/Position";
import { primaryButtonClass, tokens } from "../TokenList";
import StakedPositionRow from "./Components/StakedPositionRow";
import useRefresh from "../../../../hooks/useRefresh";
import { Web3BombappContext } from "../../../../context/Web3Bombapp";
import { BeefyContext } from "../../../../context/BeefyContext";
import Link from "../../../../components/Link";
import { classNames } from "./Components/DurationSelector";

function Positions() {
  let { address } = useParams();
  const navigate = useNavigate();
  const { slowRefresh } = useRefresh();
  const { getAllUserOwned, stakeInfo } = React.useContext(Web3BombappContext);
  const { fetchPrice } = React.useContext(BeefyContext);

  if (!tokens[address]) {
    return <div>Invalid token</div>;
  }

  const [positions, setPositions] = React.useState<PositionType[]>([]);

  useEffect(() => {
    const BTCSTAKEaddress = "0x886a4003A7A7279A3Ab342E4BB2F11153b7318a3";
    getAllUserOwned(BTCSTAKEaddress).then((tokenIds: number[]) => {
      Promise.all(tokenIds.map((tokenId: number) => {
        return stakeInfo(BTCSTAKEaddress, tokenId);
      })).then((positions: any[]) => {
        const decimals = 18;
        setPositions(positions.map((position: any, index): PositionType => {
          const amount = Number(position.amountStaked) / Math.pow(10, decimals);
          return {
            token: tokens[address],
            tokenId: tokenIds[index],
            amount: amount,
            allowWithdrawEarly: Boolean(position.allowWithdrawEarly),
            apr: Number(position.apr) / 100,
            created: Number(position.created),
            lockTime: Number(position.lockTime),
            dollarValue: fetchPrice ? fetchPrice({ id: tokens[address].beefySymbol }) * amount : null,
          };
        }));
      });
    });
  }, [fetchPrice, slowRefresh]);

  return (
    <div className="App">
      <div className="relative w-10/12 mx-auto lg:w-1/2 max-w-3xl">
        <button onClick={() => navigate(-1)} className="absolute text-2xl left-2 top-11">
          <img src={backArrow} alt="Back" />
        </button>
        <h1 className={"text-white text-2xl mt-10"}>Staked positions for {tokens[address].longName}</h1>
        <h2 className={"text-green-300 text-3xl mt-4 font-bold"}>${positions.reduce(
          (accumulator, currentValue) => accumulator + currentValue.dollarValue,
          0
        ).toFixed(2)}</h2>
        <div className="mt-8 mb-20">
          {positions.map((position, index) => (
            <StakedPositionRow key={index} position={position} />
          ))}
        </div>
        <div className="fixed bottom-0 w-10/12 lg:w-full lg:left-0 py-4">
          <div className="lg:w-1/2 lg:mx-auto max-w-3xl">
            <div className="flex justify-between gap-4">
              <Link
                to={"/#/mobile/positions/" + address + "/buy"}
                className={classNames("w-full relative", primaryButtonClass)}
              >
                <div className="-mt-3">
                  Buy Crypto
                </div>
                <div className="absolute w-full left-0">
                  <img
                    src={visaMC}
                    className="w-16 mx-auto"
                    alt="Visa Mastercard"
                  >
                  </img>
                </div>
              </Link>
              <Link
                to={"/#/mobile/positions/" + address + "/deposit"}
                className={classNames("w-full", primaryButtonClass)}
              >
                Deposit
              </Link>
            </div>
            <Link
              to={"/#/mobile/positions/" + address + "/new"}
              className={classNames("mt-5", primaryButtonClass)}
            >
              Create Stake
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Positions;
