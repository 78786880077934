import React from "react";
import backArrow from "../../assets/images/ic_arrow_back.svg";
import { useNavigate } from "react-router-dom";

function Deposit() {
  const navigate = useNavigate();

  return (
    <div className="App">
      <div className="w-10/12 relative mx-auto lg:w-1/2 max-w-3xl">
        <button onClick={() => navigate(-1)} className="absolute left-2 top-11 text-2xl">
          <img src={backArrow} alt="Back" />
        </button>
        <div>
          <h1 className={"text-white text-2xl mt-10 mx-6 font-bold"}>Deposit Coins to your BOMB Wallet</h1>
          <div className="mt-20 text-2xl text-center">
            Which Coin/Token?
          </div>
          <div className="mx-3 mt-3">
            <select
              className="block text-black bg-[#FAD02C] rounded-full p-2 text-center w-full font-bold" 
              onChange={(e) => {
                // @ts-ignore
                const prefix = window.bombRouterType === "hash" ? "/mobile" : "";
                navigate(prefix + "/positions/" + e.target.value + "/deposit");
              }}
              defaultValue=""
            >
              <option value="" disabled>Select a coin/token</option>
              <option value="0x140F62aCCC69cb24eABdC0E00b7caaC577cA5b24">BTC</option>
              {/*<option value="0x2170Ed0880ac9A755fd29B2688956BD959F933F8">ETH</option>*/}
              {/*<option value="0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c">BNB Coin</option>*/}
            </select>
          </div>
        </div>
        <div className="mt-10">&nbsp;</div>
      </div>
    </div>
  );
}

export default Deposit;
