/* eslint-disable no-unused-vars */
import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function TermsModal(
  { open, setOpen, url }: { open: boolean; setOpen: (open: boolean) => void; url: string }
) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-start justify-center p-2 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-black w-full shadow-xl transition-all"
              >
                <div>
                  <div>
                    <button
                      type="button"
                      className="inline-block float-right rounded-md border border-transparent
                     px-6 py-2 text-2xl mb-4 font-medium text-white shadow-sm"
                      onClick={() => setOpen(false)}
                    >
                      X
                    </button>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <iframe
                      src={url} className="w-full" style={{
                        height: "calc(100vh - 6rem)",
                      }}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
