/* eslint-disable */
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Loading";
import { BombApiContext } from "../../../context/BombApiContext";

function ReferredBy() {
  const navigate = useNavigate();
  const { referredBy, setReferredBy } = React.useContext(BombApiContext);
  let { code } = useParams();
  if (!code) {
    return <div>Invalid referral code</div>;
  }

  useEffect(() => {
    if (referredBy !== code) {
      if (code !== "null") {
        setReferredBy(code);
      }
    } else {
      // @ts-ignore
      const prefix = window.bombRouterType === "hash" ? "/mobile" : "";
      navigate(prefix + "/");
    }
  }, [referredBy, code]);

  return (
    <Loading type="Applying referral code..." />
  );
}

export default ReferredBy;
