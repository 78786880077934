/* eslint-disable max-len */
import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import logo from "../assets/images/bombmoney_logo.png";
import Link from "./Link";
import { Web3BombappContext } from "../context/Web3Bombapp";
import { primaryButtonClass } from "../pages/Mobile/Positions/TokenList";
import { classNames } from "../pages/Mobile/Positions/[token]/Components/DurationSelector";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

function abbreviateAddress(walletAddress: string, beforeLength: number = 6, afterLength: number = 4): string {
  return walletAddress.substring(0, beforeLength) + "..." + walletAddress.substring(walletAddress.length - afterLength, walletAddress.length);
}

const menu = [
  {
    name: "Portfolio",
    href: "/home"
  },
  {
    name: "Earn",
    href: "/earn"
  },
  // {
  //   name: "View Positions",
  //   href: "/staked"
  // },
  {
    name: "Refer",
    href: "/refer"
  },
  {
    name: "Account",
    children: [
      {
        name: "Buy",
        href: "/buy"
      },
      {
        name: "Deposit",
        href: "/deposit"
      },
      {
        name: "Withdraw",
        href: "/withdraw"
      }
    ]
  }
];

export default function Header() {
  const { walletAddress, web3Connect, web3Disconnect } = React.useContext(Web3BombappContext);

  return (
    <Popover className="relative bg-black Header">
      <div className="mx-auto">
        <div className="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10 px-6">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to={"/#/mobile/home"}>
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto sm:h-10"
                src={logo}
                alt=""
              />
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-black p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex h-8">
            {menu.map((item, key) => (
              <div key={key} className="group relative">
                <Link to={item.href} className="block h-full text-base font-medium text-white hover:text-bomb-yellow">
                  {item.name}&nbsp;
                  {item.children && item.children.length > 0 && (
                    <ChevronDownIcon className="inline-block h-4 w-4" aria-hidden="true" />
                  )}
                </Link>
                {item.children && item.children.length > 0 && (
                  <div className="hidden group-hover:block absolute bg-black left-0 -ml-2 z-10 rounded border border-white">
                    {item.children && item.children.map((child, key) => (
                      <Link 
                        key={key} 
                        to={child.href} 
                        className="block text-base font-medium text-white hover:text-bomb-yellow
                        py-3 px-4 border-b border-b-white last-of-type:border-b-0 overflow-hidden"
                      >
                        {child.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </Popover.Group>
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            {walletAddress ? (
              <a
                href="#"
                className={classNames("ml-8 inline-flex items-center justify-center whitespace-nowrap", primaryButtonClass)}
                onClick={(e) => {  e.preventDefault(); web3Disconnect(); return false; }}
              >
                {abbreviateAddress(walletAddress)}
              </a>
            ) : (
              <a
                href="#"
                className={classNames("ml-8 inline-flex items-center justify-center whitespace-nowrap", primaryButtonClass)}
                onClick={(e) => { e.preventDefault(); web3Connect(); return false; }}
              >
                Connect
              </a>
            )}
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform transition md:hidden z-20">
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-black shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src={logo}
                    alt="bomb.app"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-black p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="space-y-6 py-6 px-5">
              <div className="grid grid-cols-1 gap-y-4 gap-x-8 text-center">
                {menu.map((item, key) => (
                  <>
                    {item.href && (
                      <a key={key} href={item.href} className="text-base font-medium text-white hover:text-bomb-yellow">
                        {item.name}
                      </a>
                    )}
                    {item.children && item.children.map((child, key) => (
                      <a key={key} href={child.href} className="text-base font-medium text-white hover:text-bomb-yellow">
                        {child.name}
                      </a>
                    ))}
                  </>
                ))}
              </div>
              <div>
                {walletAddress ? (
                  <a
                    href="#"
                    className={classNames("flex w-full items-center justify-center", primaryButtonClass)}
                    onClick={(e) => {  e.preventDefault(); web3Disconnect(); return false; }}
                  >
                    {abbreviateAddress(walletAddress)}
                  </a>
                ) : (
                  <a
                    href="#"
                    className={classNames("flex w-full items-center justify-center", primaryButtonClass)}
                    onClick={(e) => { e.preventDefault(); web3Connect(); return false; }}
                  >
                    Connect
                  </a>
                )}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}