import React, { useEffect, useState } from "react";
import axios from "axios";

const CACHE_TIMEOUT_MS = 60 * 1000; // 1 minute(s)
const priceCache = {
  cache: new Map(),
  lastUpdated: undefined,
};

// Time-based cache buster
export const getApiCacheBuster = () => {
  return Math.trunc(Date.now() / (1000 * 60));
};

// function isCached(id) {
//   return priceCache.cache.has(id);
// }

function getCachedPrice(id) {
  return priceCache.cache.get(id);
}

function maybeUpdateCache() {
  const currentTimestamp = new Date();
  if (
    priceCache.lastUpdated &&
        currentTimestamp.getTime() > priceCache.lastUpdated.getTime() + CACHE_TIMEOUT_MS
  ) {
    initializePriceCache();
    // console.trace('price cache updated')
  }
}

const fetchTokens = async () => {
  const cacheBuster = getApiCacheBuster();

  try {
    const response = await axios.get(`https://api.app.peghub.com/prices?_=${cacheBuster}`);
    return response.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const fetchLPs = async () => {
  const cacheBuster = getApiCacheBuster();

  try {
    const response = await axios.get(`https://api.app.peghub.com/lps?_=${cacheBuster}`);
    return response.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const fetchApys = async () => {
  const cacheBuster = getApiCacheBuster();

  try {
    const response = await axios.get(`https://api.app.peghub.com/apy/breakdown?_=${cacheBuster}`);
    return response.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const fetchTvls = async () => {
  const cacheBuster = getApiCacheBuster();

  try {
    const response = await axios.get(`https://api.app.peghub.com/tvl?_=${cacheBuster}`);
    return response.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const oracleEndpoints = {
  tokens: () => fetchTokens(),
  lps: () => fetchLPs(),
  apys: () => fetchApys(),
  tvls: () => fetchTvls(),
};

let pricesLoadedPromise;
export function whenPricesLoaded() {
  return pricesLoadedPromise;
}

let apysLoadedPromise;
export function whenApysLoaded() {
  return apysLoadedPromise;
}

let tvlsLoadedPromise;
export function whenTvlsLoaded() {
  return tvlsLoadedPromise;
}

export function initializePriceCache() {
  priceCache.lastUpdated = new Date();

  const oracleToIds = new Map();
  if (!oracleToIds.has("tokens")) {
    oracleToIds.set("tokens", []);
  }
  // oracleToIds.get('tokens').push(asset);

  if (!oracleToIds.has("lps")) {
    oracleToIds.set("lps", []);
  }

  if (!oracleToIds.has("apys")) {
    oracleToIds.set("apys", []);
  }

  // if (!oracleToIds.has('tvls')) {
  //     oracleToIds.set('tvls', []);
  // }

  const pricePromises = ["tokens", "lps"].map(key => oracleEndpoints[key](oracleToIds.get(key)));
  pricesLoadedPromise = Promise.all(pricePromises).then(results => {
    const allPrices = results.reduce(
      (accPrices, curPrices) => ({ ...accPrices, ...curPrices }),
      {}
    );
    Object.entries(allPrices).forEach(([id, price]) => {
      priceCache.cache.set(id, price);
      if (id.indexOf("-") !== -1) {
        // This is an LP, add it to the cache in a more friendly format so we can retrieve it
        const splitId = id.split("-");
        priceCache.cache.set(splitId[1] + "-" + splitId[2], price);
        if (splitId[2] && splitId[2].toUpperCase() === "MATIC") { // Handle inconsistency in beefy API pool names
          priceCache.cache.set(splitId[1] + "-wmatic", price);
        }
      } else if (id === "AVAX") {
        priceCache.cache.set("WAVAX", price);
      } else if (id === "WBNB") {
        priceCache.cache.set("BNB", price);
      }
    });
  });

  const apyPromises = ["apys"].map(key => oracleEndpoints[key](oracleToIds.get(key)));
  apysLoadedPromise = Promise.all(apyPromises).then(results => {
    const allPrices = results.reduce(
      (accPrices, curPrices) => ({ ...accPrices, ...curPrices }),
      {}
    );
    Object.entries(allPrices).forEach(([id, price]) => {
      if (id.indexOf("-") !== -1) {
        // This is an LP, add it to the cache in a more friendly format so we can retrieve it
        const splitId = id.split("-");
        priceCache.cache.set("apy_" + splitId[1] + "-" + splitId[2], price.vaultAprWithoutPerformanceFee);
      } else if (id === "AVAX") {
        priceCache.cache.set("apy_" + "WAVAX", price.vaultAprWithoutPerformanceFee);
      } else if (id === "WBNB") {
        priceCache.cache.set("apy_" + "BNB", price.vaultAprWithoutPerformanceFee);
      }
    });
  });

  // const tvlPromises = ['tvls'].map(key => oracleEndpoints[key](oracleToIds.get(key)));
  // tvlsLoadedPromise = Promise.all(tvlPromises).then(results => {
  //     const allPrices = results.reduce(
  //       (accPrices, curPrices) => ({ ...accPrices, ...curPrices }),
  //       {}
  //     );
  //     Object.values(allPrices).forEach(allPricesForChain => {
  //         Object.entries(allPricesForChain).forEach(([id, price]) => {
  //             if (id.indexOf('-') !== -1) {
  //                 // This is an LP, add it to the cache in a more friendly format so we can retrieve it
  //                 const splitId = id.split('-');
  //                 priceCache.cache.set('tvl_' + splitId[1] + '-' + splitId[2], price)
  //             } else if (id === 'AVAX') {
  //                 priceCache.cache.set('tvl_' + 'WAVAX', price)
  //             } else if (id === 'WBNB') {
  //                 priceCache.cache.set('tvl_' + 'BNB', price)
  //             }
  //         });
  //     });
  // });
}

export const fetchPrice = ({ id }) => {
  if (id === undefined) {
    console.error("Undefined pair");
    return 0;
  }

  maybeUpdateCache();

  const price = getCachedPrice(id);
  if (!price) {
    throw new Error("Beefy price not found for " + id);
  }

  return price;
};

export const BeefyContext = React.createContext(null);

/* eslint-disable react/prop-types */
export function BeefyContextProvider({ children }) {
  const [apysLoaded, setApysLoaded] = useState(false);
  const [pricesLoaded, setPricesLoaded] = useState(false);

  useEffect(() => {
    initializePriceCache();
    whenApysLoaded().then(() => {
      setApysLoaded(true);
    });
    whenPricesLoaded().then(() => {
      setPricesLoaded(true);
    });
  }, []);

  return <BeefyContext.Provider
    value={{
      apysLoaded,
      pricesLoaded,
      fetchPrice: ({ id }) => {
        if (!pricesLoaded || !apysLoaded) {
          return null;
        }

        return fetchPrice({ id });
      },
    }}
  >{children}</BeefyContext.Provider>;
}
