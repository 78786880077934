import React, { useEffect } from "react";
import { Link } from "../components/Link";
import { PositionType } from "../../types/Position";
import { primaryButtonClass, tokens } from "./Mobile/Positions/TokenList";
import useRefresh from "../hooks/useRefresh";
import { Web3BombappContext } from "../context/Web3Bombapp";
import { BeefyContext } from "../context/BeefyContext";
import { TokenType } from "../../types/Token";
import { classNames } from "./Mobile/Positions/[token]/Components/DurationSelector";
import TokenSymbol from "../components/TokenSymbol";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "./Loading";

export interface TokenWithBalanceType {
  token: TokenType;
  walletBalance?: number;
  walletBalanceUsd?: number;
}

function Home() {
  const { slowRefresh } = useRefresh();
  const { getAllUserOwned, stakeInfo, getBalance } = React.useContext(Web3BombappContext);
  const [walletAssets, setWalletAssets] = React.useState<TokenWithBalanceType[]|null>(null);
  const [positions, setPositions] = React.useState<PositionType[]|null>(null);
  const { fetchPrice } = React.useContext(BeefyContext);
  const BTCAddress = "0x140F62aCCC69cb24eABdC0E00b7caaC577cA5b24";
  const BTCSTAKEaddress = "0x886a4003A7A7279A3Ab342E4BB2F11153b7318a3";
  const navigate = useNavigate();
  const location = useLocation();
  const isLanding = location.pathname === "/";

  useEffect(() => {
    Promise.all(Object.values(tokens).map((token: TokenType) => {
      if (token.comingSoon) {
        return Promise.resolve(null);
      }
      return getBalance(token.address);
    })).then((balancesArray: any) => {
      setWalletAssets(Object.values(tokens).map((token: TokenType, index: number): TokenWithBalanceType => {
        if (!balancesArray[index]) {
          return { token, walletBalance: 0, walletBalanceUsd: 0 };
        }
        return {
          token,
          walletBalance: balancesArray[index],
          walletBalanceUsd: fetchPrice ? fetchPrice({ id: token.beefySymbol }) * balancesArray[index] : null,
        };
      }));
    });

    getAllUserOwned(BTCSTAKEaddress).then((tokenIds: number[]) => {
      Promise.all(tokenIds.map((tokenId: number) => {
        return stakeInfo(BTCSTAKEaddress, tokenId);
      })).then((positions: any[]) => {
        const decimals = 18;
        setPositions(positions.map((position: any, index): PositionType => {
          const amount = Number(position.amountStaked) / Math.pow(10, decimals);
          return {
            token: tokens[BTCAddress],
            tokenId: tokenIds[index],
            amount: amount,
            allowWithdrawEarly: Boolean(position.allowWithdrawEarly),
            apr: Number(position.apr) / 100,
            created: Number(position.created),
            lockTime: Number(position.lockTime),
            dollarValue: fetchPrice ? fetchPrice({ id: tokens[BTCAddress].beefySymbol }) * amount : null,
          };
        }));
      });
    });
  }, [fetchPrice, slowRefresh]);

  useEffect(() => {
    if (!isLanding) {
      return;
    }

    if (positions === null || walletAssets === null) {
      return;
    }

    // @ts-ignore
    const prefix = window.bombRouterType === "hash" ? "/mobile" : "";
    if (positions.length === 0) {
      navigate(prefix + "/earn");
    } else {
      navigate(prefix + "/home");
    }
  }, [positions, walletAssets, isLanding]);

  if (isLanding) {
    return <Loading type="Loading" />;
  }

  if (!walletAssets || !positions) {
    return <Loading type="Loading" />;
  }

  const stakedAssetTotal = positions.reduce(
    (accumulator, currentValue) => accumulator + currentValue.dollarValue,
    0
  ).toFixed(2);
  const walletAssetsTotal = walletAssets.reduce(
    (accumulator, currentValue) => accumulator + currentValue.walletBalanceUsd,
    0
  ).toFixed(2);

  const stakedTotalsPerAsset: { [key: string]: {token: TokenType; amount: number; dollarValue: number}; } = {};
  for (const position of positions) {
    if (!stakedTotalsPerAsset[position.token.address]) {
      stakedTotalsPerAsset[position.token.address] = { token: position.token, amount: 0, dollarValue: 0 };
    }
    stakedTotalsPerAsset[position.token.address].amount += position.amount;
    stakedTotalsPerAsset[position.token.address].dollarValue += position.dollarValue;
  }

  return (
    <div className="App">
      <div className="w-10/12 relative mx-auto lg:w-1/2 max-w-3xl">
        <h1 className={"text-white text-2xl mt-10"}>Portfolio summary</h1>
        <h2 className={"text-green-300 text-3xl mt-4 font-bold"}>
          ${(Number(stakedAssetTotal) + Number(walletAssetsTotal)).toFixed(2)}
        </h2>

        <div className="lg:grid lg:grid-cols-2 lg:gap-x-5 mt-10">
          <div>
            <div className="flex bg-[#202126] text-white font-bold rounded-full p-3 px-5 justify-items-center">
              <div className="text-left grow">
                Wallet
              </div>
              <div className="text-right">
                ${walletAssetsTotal}
              </div>
            </div>
            {walletAssets.map((asset, i) => (
              <div key={i} className="grid block grid-cols-2 gap-4 mt-5 rounded py-1 px-2">
                <div className="flex items-center justify-center lg:items-center lg:justify-start">
                  <div>
                    <TokenSymbol address={asset.token.address} className="inline w-12 h-12" />
                  </div>
                  <div className="w-1/3 ml-4 text-left">
                    <div className="text-sm font-semibold text-gray-500">{asset.token.longName}</div>
                    <div className="font-semibold">{asset.token.shortName}</div>
                    <div className="text-sm text-gray-300">
                      <Link
                        to={"/#/mobile/positions/" + asset.token.address + "/buy"}
                        className="hover:text-bomb-yellow"
                      >Buy</Link>&nbsp;&nbsp;&nbsp;
                      <Link
                        to={"/#/mobile/positions/" + asset.token.address + "/deposit"}
                        className="hover:text-bomb-yellow"
                      >Deposit</Link>
                    </div>
                  </div>
                </div>
                <div className="self-center lg:justify-self-end">
                  <span className="font-bold">
                    {asset.walletBalance.toFixed(4)} (${asset.walletBalanceUsd.toFixed(2)})
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div
              className="flex bg-[#202126] text-white font-bold rounded-full p-3 px-5 justify-items-center mt-3 lg:mt-0"
            >
              <div className="text-left grow">
                Stake positions
              </div>
              <div className="text-right">
                ${stakedAssetTotal}
              </div>
            </div>
            {Object.values(stakedTotalsPerAsset).map((position, i) => (
              <Link
                key={i}
                to={!position.token.comingSoon ? "/#/mobile/positions/" + position.token.address : undefined}
                className="grid block grid-cols-2 gap-4 mt-5 bg-bomb-asset-row
             rounded py-1 px-2 transition-colors hover:bg-bomb-asset-row-hover"
              >
                <div className="flex items-center justify-center lg:items-start lg:justify-start">
                  <div>
                    <TokenSymbol address={position.token.address} className="inline w-12 h-12" />
                  </div>
                  <div className="w-1/3 ml-4 text-left">
                    <div className="text-sm font-semibold text-gray-500">{position.token.longName}</div>
                    <div className="font-semibold">{position.token.shortName}</div>
                  </div>
                </div>
                <div className="self-center lg:justify-self-end">
                  <div className="font-bold">
                    {position.amount.toFixed(4)} (${position.dollarValue.toFixed(2)})
                  </div>
                </div>
              </Link>
            ))}
            {Object.values(stakedTotalsPerAsset).length <= 0 && (
              <div className="text-justify ml-6 text-white mt-5">
                Looks like you don&apos;t have any stake positions yet. <br />
                Not to worry, <Link to={"/#/mobile/earn"} className="text-[#FAD02C]">click here</Link> to create one!
              </div>
            )}
          </div>
        </div>

        <div className="fixed bottom-0 w-10/12 lg:w-full lg:left-0 py-4">
          <div className="lg:w-1/2 lg:mx-auto max-w-3xl">
            <div className="flex justify-between gap-4">
              <Link
                to={"/#/mobile/deposit"}
                className={classNames("w-full", primaryButtonClass)}
              >
                Deposit
              </Link>
              <Link
                to={"/#/mobile/withdraw"}
                className={classNames("w-full", primaryButtonClass)}
              >
                Withdraw
              </Link>
            </div>
            <Link
              to={"/#/mobile/earn"}
              className="block mt-5 bg-[#FAD02C] py-3 w-full rounded-full text-xl font-bold text-black"
            >
              Start earning!
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
