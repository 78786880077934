import React, { useEffect, useState } from "react";
import backArrow from "../../../../assets/images/ic_arrow_back.svg";
import { useNavigate, useParams } from "react-router-dom";
import { primaryButtonClass, tokens } from "../TokenList";
import NetworkSelector from "./Components/NetworkSelector";
import { Web3BombappContext } from "../../../../context/Web3Bombapp";
import InputFromWallet from "../../../../components/InputFromWallet";
import useRefresh from "../../../../hooks/useRefresh";
import TransactionModal from "./Components/TransactionModal";
import { classNames } from "./Components/DurationSelector";

function WithdrawCoin() {
  const { address } = useParams();
  const navigate = useNavigate();
  const { fastRefresh } = useRefresh();
  const [allowance, setAllowance] = React.useState<number>(0);
  const [network, setNetwork] = React.useState<any|null>([56, "Binance Smart Chain", "binance"]);
  const [transaction, setTransaction] = useState(null);
  const { walletAddress, withdrawAsset, getAllowance, approveContract } = React.useContext(Web3BombappContext);
  const [withdrawalWalletAddress, setWithdrawalWalletAddress] = React.useState<string | null>(walletAddress);
  const [amount, setAmount] = React.useState<string | null>();
  const spender = "0x2a06800f3F935024d327D6C632Ca000f00B9CFEd"; // Bridge contract address

  useEffect(() => {
    getAllowance(address, spender).then((allowance: number) => {
      setAllowance(allowance);
    });
  }, [fastRefresh]);

  const token = tokens[address];

  return (
    <div className="App">
      <TransactionModal transaction={transaction} setTransaction={setTransaction} />
      <div className="w-10/12 relative mx-auto lg:w-1/2 max-w-3xl">
        <button onClick={() => navigate(-1)} className="absolute left-2 top-11 text-2xl">
          <img src={backArrow} alt="Back" />
        </button>
        <div>
          <h1 className={"text-white text-2xl mt-10 mx-6 font-bold"}>
            Withdraw {token.shortName} from your BOMB Wallet
          </h1>
          <div className="mt-10 text-2xl text-center">
            To which Network?
          </div>
          <div className="mx-3 mt-3">
            <NetworkSelector
              defaultValue={network}
              onChange={(value) => { setNetwork(value); }}
              networks={["binance"]}
            />
          </div>
        </div>
        {network && (
          <form
            className="px-4"
            onSubmit={(e) => {
              e.preventDefault();

              if (!withdrawalWalletAddress) {
                alert("Please select a withdrawal address");
                return;
              }

              if (!amount) {
                alert("Please select an amount");
                return;
              }

              if (allowance <= 0) {
                (async() => {
                  const tx = approveContract(address, spender);
                  setTransaction(tx);
                })();
                return;
              }

              (async () => {
                const tx = withdrawAsset(address, amount, withdrawalWalletAddress, network[0]);
                setTransaction(tx);
              })();
            }}
          >
            <div className="mt-8">
              <div className="mb-1 ml-5 text-left text-gray-400">{network[1]} Wallet Address</div>
              <input
                type="text"
                className="w-full px-5 py-3 text-black border-b border-gray-500" placeholder="Wallet address"
                value={withdrawalWalletAddress}
                onChange={(e) => { setWithdrawalWalletAddress(e.target.value); }}
              />
            </div>

            <div className="mt-5">
              <div className="mb-1 ml-5 text-left text-gray-400">Amount</div>
              <InputFromWallet
                tokenAddress={address}
                defaultValue={amount}
                onChange={(value) => { setAmount(value); }}
              />
            </div>
            <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-500" />
            <div className="mt-5">
              <button className={classNames("w-full", primaryButtonClass)}>
                {allowance > 0 ? "Withdraw" : "Approve"}
              </button>
            </div>
          </form>
        )}
        <div className="mt-10">&nbsp;</div>
      </div>
    </div>
  );
}

export default WithdrawCoin;
