import React from "react";
import { Link as WrappedLink } from "react-router-dom";

export const Link = (props: any) => {
  const passedProps = {
    ...props,
  };

  // @ts-ignore
  if (window.bombRouterType !== "hash" && props.to) {
    passedProps.to = props.to.replace("/#/mobile", "");

    return <WrappedLink {...passedProps} />;
  }

  if (props.to) {
    passedProps.to = props.to.replace("/#/", "/");
  }

  return <WrappedLink {...passedProps} />;
};

export default Link;
