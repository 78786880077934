import React from "react";
import Header from "./Header";
import { Web3BombAppProvider } from "../context/Web3Bombapp";
import { RefreshContextProvider } from "../context/RefreshContext";
import { BeefyContextProvider } from "../context/BeefyContext";
import { BombApiContextProvider } from "../context/BombApiContext";
import { Outlet } from "react-router-dom";

export default function DesktopLayout() {
  return (
    <RefreshContextProvider>
      <Web3BombAppProvider>
        <BombApiContextProvider>
          <BeefyContextProvider>
            <div className="Desktop">
              <Header />
              <Outlet />
            </div>
          </BeefyContextProvider>
        </BombApiContextProvider>
      </Web3BombAppProvider>
    </RefreshContextProvider>
  );
}
