/* eslint-disable */
import React, { useEffect } from "react";
import { Web3BombappContext } from "../../../../context/Web3Bombapp";
import { BombApiContext } from "../../../../context/BombApiContext";
import backArrow from "../../../../assets/images/ic_arrow_back.svg";
import { useNavigate, useParams } from "react-router-dom";

const BTCAddress = "0x140F62aCCC69cb24eABdC0E00b7caaC577cA5b24";

function Buy() {
  let { address } = useParams();
  if (!address) {
    address = BTCAddress;
  }
  const navigate = useNavigate();
  const network = "ethereum";
  const [depositMinimumAmount, setDepositMinimumAmount] = React.useState<any | null>();
  const [depositWalletAddress, setDepositWalletAddress] = React.useState<string | null>(null);
  const { walletAddress } = React.useContext(Web3BombappContext);
  const { getDepositAddress, getDepositAsset } = React.useContext(BombApiContext);

  useEffect(() => {
    setDepositWalletAddress("");
    if (network) {
      getDepositAddress(network, walletAddress).then((response: any) => {
        if (response.error) {
          alert(response.error);
          return;
        }
        setDepositWalletAddress(response.depositAddress.address);
      });
      getDepositAsset(address).then((response: any) => {
        if (response.error) {
          alert(response.error);
          return;
        }
        setDepositMinimumAmount(response.depositAsset.minimum);
      });
    }
  }, [network]);

  if (!depositWalletAddress) {
    return <div>Loading...</div>;
  }

  const apiKey = "pk_prod_0vrzMi6KLQovJuRmTHacqL5Di2XSIcdUUczgtayejWs0";

  return (
    <div className="App">
      <div className="relative w-full lg:w-1/2 lg:mx-auto max-w-3xl">
        <button onClick={() => navigate(-1)} className="absolute text-2xl left-16 top-11">
          <img src={backArrow} alt="Back" />
        </button>
        <h1 className={"text-white text-2xl mt-10 font-bold"}>Buy Crypto</h1>
        <h2 className="font-bold">Minimum purchase amount: <span className="text-lg">{depositMinimumAmount} BTC</span></h2>
        <div className="mt-10">
          <iframe
            src={"https://widget.onramper.com?color=266677&apiKey=" + apiKey + "&supportSwap=false&supportSell=false&defaultCrypto=WBTC&onlyCryptos=WBTC&" +
              "wallets=ETH:" + depositWalletAddress +
              ",WBTC:" + depositWalletAddress +
              "&isAddressEditable=false&darkMode=true"}
            title="Onramper widget"
            frameBorder="0"
            className="mx-auto"
            allow="accelerometer;
    autoplay; camera; gyroscope; payment"
            style={{
              border: "none",
              boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.1)",
              width: '100%',
              maxWidth: '482px',
              height: 'calc(100vh - 9rem)',
            }}
          >
            Loading...
          </iframe>
        </div>
      </div>
    </div>
  );
}

export default Buy;
