import React, { useState } from "react";
import logo from "../../assets/images/bomb_3d_logo.png";
import copyIcon from "../../assets/images/copy_icon.png";
import { BombApiContext } from "../../context/BombApiContext";
import TermsModal from "./Positions/[token]/Components/TermsModal";
import { Toaster } from "react-hot-toast";
import { notifyFailed, notifySuccess } from "../../components/Toast";
import backArrow from "../../assets/images/ic_arrow_back.svg";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";

function Refer() {
  const { userInfo } = React.useContext(BombApiContext);

  if (!userInfo) {
    return <Loading type="Loading" />;
  }

  const [referDetailsOpen, setReferDetailsOpen] = useState(false);
  const navigate = useNavigate();

  function CopyReferalCode() {
    let code: HTMLInputElement = document.getElementById("referralCode") as HTMLInputElement;
    code.select();
    document.execCommand("Copy");
  }

  return (
    <div className="App">
      <TermsModal open={referDetailsOpen} setOpen={setReferDetailsOpen} url="https://www.bombmoney.com/refer/" />
      <div className="w-10/12 lg:w-1/2 relative mx-auto max-w-3xl">
        <button onClick={() => navigate(-1)} className="absolute left-2 top-11 text-2xl">
          <img src={backArrow} alt="Back" />
        </button>
        <h1 className={"text-white text-2xl mt-10 font-bold mx-6"}>BOMB Money Referral Program</h1>
        <div className="flex items-center">
          <img src={logo} className="Refer-logo w-48 h-48" alt="logo" />
          <div className="text-[#FAD02C] text-5xl font-bold  mr-6">
            EARN $50
          </div>
        </div>
        <ul className="text-left list-none pl-6 text-lg mr-6">
          <li className="flex">
            <div className="text-[#FAD02C] font-bold pr-3">&gt;</div>
            <div>Share your referral Code</div>
          </li>
          <li className="flex">
            <div className="text-[#FAD02C] font-bold pr-3">&gt;</div>
            <div>When they stake $100 or more and use your cod you both earn a $50 Bitcoin Stake!</div>
          </li>
        </ul>
        <a
          href="https://www.bombmoney.com/refer/"
          target="_blank"
          className="block text-right text-[#FAD02C] mr-6 mt-3"
          rel="noreferrer"
          onClick={(e) => {
            e.preventDefault();
            setReferDetailsOpen(true);
          }}
        >
          Click to find out more &gt;
        </a>
        <div className="bg-[#202126] rounded-lg mx-6 mt-6 font-bold p-2">
          <div className="text-lg">
            Total Bonus Earned:
          </div>
          <div className="text-3xl">
            $0
          </div>
        </div>
        <div className="mt-6 text-lg font-bold">
          REFERRAL CODE
        </div>
        <div className="relative mx-6 mb-10">
          <input 
            className="bg-white rounded-full text-black font-bold text-2xl py-1 text-center w-full"
            readOnly
            value={userInfo.referralCode}
            id="referralCode"
          />
          <img
            src={copyIcon} className="w-8 h-8 absolute right-4 top-1 cursor-pointer" 
            onClick={() => navigator.clipboard.writeText(userInfo.referralCode).then(() => {
              notifySuccess("Successfully copied");
            }).catch(() => {
              try {
                CopyReferalCode();
                notifySuccess("Successfully copied");
              } catch (e) { 
                notifyFailed("Something went wrong "+ e);
              }
            })}
            alt="copy"
          />
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default Refer;
