import React, { MouseEventHandler } from "react";
import logo from "../assets/images/logo.png";
import { classNames } from "./Mobile/Positions/[token]/Components/DurationSelector";
import { primaryButtonClass } from "./Mobile/Positions/TokenList";

function SwitchChain({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) {

  return (
    <div className="App">
      <div className="relative w-10/12 mx-auto lg:w-1/2 max-w-3xl">
        <div className="w-1/3 mx-auto">
          <img src={logo} alt="BOMB" />
        </div>
        <div className="text-lg font-bold">
          Welcome to the BOMB Money App
        </div>
        <hr className="mt-10" />
        <h1 className={"text-white text-xl mt-2"}>You are currently connected to a non-supported network.</h1>
        <button
          className={classNames("mt-3 w-full", primaryButtonClass)}
          onClick={onClick}
        >
          Switch to BOMB Chain
        </button>
        <hr className="mt-5" />

        <div className="text-lg mt-5">
          In order to interact with the BOMB Money Web3 app, you need to connect your wallet to BOMB Chain.
        </div>
      </div>
    </div>
  );
}

export default SwitchChain;
