import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from "react-responsive-carousel";
import TokenSymbol from "../../../components/TokenSymbol";
import { TokenType } from "../../../../types/Token";
import useRefresh from "../../../hooks/useRefresh";
import { Web3BombappContext } from "../../../context/Web3Bombapp";
import TermsModal from "./[token]/Components/TermsModal";
import { Link } from "../../../components/Link";
import { classNames } from "./[token]/Components/DurationSelector";
import { TokenWithBalanceType } from "../../Home";
import { BeefyContext } from "../../../context/BeefyContext";
import { Disclosure, Transition } from "@headlessui/react";
import visaMC from "../../../assets/images/visa-mastercard.png";

export const tokens: { [key: string]: TokenType } = {
  "0x140F62aCCC69cb24eABdC0E00b7caaC577cA5b24": {
    longName: "BITCOIN",
    shortName: "BTC",
    beefySymbol: "BTCB",
    comingSoon: false,
    address: "0x140F62aCCC69cb24eABdC0E00b7caaC577cA5b24",
    stakeAddress: "0x886a4003A7A7279A3Ab342E4BB2F11153b7318a3",
    image: "https://swap.bomb.app/images/tokens/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c.png"
  },
  // "0x1356Cff3ffDDF918F13A549861b4Fe936Ff895D4": {
  //   longName: "Binance USD",
  //   shortName: "BUSD",
  //   beefySymbol: "BUSD",
  //   comingSoon: true,
  //   address: "0x1356Cff3ffDDF918F13A549861b4Fe936Ff895D4",
  //   stakeAddress: "0x130780e0d9FB72377EeD3EfbE7e76FF5CFc73D3e",
  //   image: "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
  // },
  // "0x2170Ed0880ac9A755fd29B2688956BD959F933F8": {
  //   longName: "ETHEREUM",
  //   shortName: "ETH",
  //   beefySymbol: "ETH",
  //   comingSoon: true,
  //   address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
  //   stakeAddress: "0x886a4003A7A7279A3Ab342E4BB2F11153b7318a3",
  // },
  // "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
  //   longName: "BNB Coin",
  //   shortName: "BNB",
  //   beefySymbol: "BNB",
  //   comingSoon: true,
  //   address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  //   stakeAddress: "0x886a4003A7A7279A3Ab342E4BB2F11153b7318a3",
  // },
  // "0xCC42724C6683B7E57334c4E856f4c9965ED682bD": {
  //  longName: "POLYGON",
  //  shortName: "MATIC",
  //  comingSoon: true,
  //  image: "https://swap.bomb.app/images/tokens/matic-square.jpg",
  //  address: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
  //},
  // "0x0Eb3a705fc54725037CC9e008bDede697f62F335": {
  //   longName: "COSMOS",
  //   shortName: "ATOM",
  //   comingSoon: true,
  //   address: "0x0Eb3a705fc54725037CC9e008bDede697f62F335",
  // },
};

export const primaryButtonClass = "block bg-[#FAD02C] p-3 rounded-full text-lg" +
  " font-bold text-black hover:shadow hover:shadow-[#FAD02C]";


function TokenList() {
  const [tokensWithInfo, setTokensWithInfo] = React.useState<TokenType[]>([]);
  const [balances, setBalances] = React.useState<{[key: string]: TokenWithBalanceType}>({});
  const { getAllLockOptions, getBalance } = React.useContext(Web3BombappContext);
  const { fetchPrice } = React.useContext(BeefyContext);
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    Promise.all(Object.values(tokens).map((token: TokenType) => {
      if (token.comingSoon) {
        return Promise.resolve(null);
      }
      return getAllLockOptions(token.stakeAddress);
    })).then((stakingInfoArray: any) => {
      setTokensWithInfo(Object.values(tokens).map((token: TokenType, index: number): TokenType => {
        if (!stakingInfoArray[index]) {
          return token;
        }

        const aprs = stakingInfoArray[index].map((stakingInfo: any) => {
          return Number(stakingInfo[0]);
        });
        return {
          ...token,
          highestApr: Math.max(...aprs) / 100,
        };
      }));
    });
  }, [slowRefresh]);

  useEffect(() => {
    const balances: {[key: string]: TokenWithBalanceType} = {};
    tokensWithInfo.forEach((token: TokenType) => {
      getBalance(token.address).then((balance: number) => {
        balances[token.address] = {
          token: token,
          walletBalance: balance,
          walletBalanceUsd: fetchPrice ? fetchPrice({ id: token.beefySymbol }) * balance : null,
        };
        setBalances(balances);
      });
    });
  }, [tokensWithInfo, slowRefresh]);

  const [howToOpen, setHowToOpen] = useState(false);

  return (
    <div className="App">
      <TermsModal open={howToOpen} setOpen={setHowToOpen} url="https://bomb.app/help" />
      <div className="relative w-10/12 mx-auto lg:w-1/2 max-w-3xl">
        <h1 className={"text-white text-2xl mt-10"}>Select a crypto</h1>
        <div className="grid grid-cols-2 gap-4 mt-6">
          <div className="text-[#D9D09D]">
            Start Staking
          </div>
          <div className="text-[#D9D09D]">
            Earn Up To
          </div>
        </div>
        {tokensWithInfo.map((token, index) => (
          <Disclosure key={index}>
            {({ open }) => (
              <div className={open ? "bg-[#202126] rounded" : ""}>
                <Disclosure.Button
                  className="grid w-full block grid-cols-2 gap-4 mt-5 bg-bomb-asset-row
                              rounded py-1 px-2 transition-colors hover:bg-bomb-asset-row-hover"
                >
                  <div className="flex items-center justify-start">
                    <div>
                      <TokenSymbol address={token.address} className="inline w-12 h-12" />
                    </div>
                    <div className="w-1/3 lg:w-2/3 ml-4 text-left">
                      <div className="text-sm font-semibold text-gray-500">{token.longName}</div>
                      <div className="font-semibold">{token.shortName}</div>
                      <div className="text-sm text-green-300 whitespace-nowrap">
                        Available:&nbsp;
                        {balances[token.address] && balances[token.address].walletBalance.toFixed(4)} {token.shortName}
                      </div>
                    </div>
                  </div>
                  <div className="text-green-300 self-center justify-self-end">
                    {
                      token.comingSoon ?
                        <span className="text-lg font-light">Coming Soon</span> :
                        <span className="text-3xl font-bold">{token.highestApr}%</span>
                    }
                  </div>
                </Disclosure.Button>
                <Transition
                  className="transition-all duration-500 overflow-hidden"
                  enterFrom="transform scale-95 opacity-0 max-h-0"
                  enterTo="transform scale-100 opacity-100 max-h-96"
                  leaveFrom="transform scale-100 opacity-100 max-h-96"
                  leaveTo="transform scale-95 opacity-0 max-h-0"
                >
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                    <div className="w-full lg:left-0 py-4">
                      <div className="lg:mx-auto max-w-3xl">
                        <div className="flex justify-between gap-4">
                          <Link
                            to={"/#/mobile/positions/" + token.address + "/buy"}
                            className={classNames("w-full relative", primaryButtonClass)}
                          >
                            <div className="-mt-3">
                              Buy {token.shortName}
                            </div>
                            <div className="absolute w-full left-0">
                              <img
                                src={visaMC}
                                className="w-16 mx-auto"
                                alt="Visa Mastercard"
                              >
                              </img>
                            </div>
                          </Link>
                          <Link
                            to={"/#/mobile/positions/" + token.address + "/deposit"}
                            className={classNames("w-full", primaryButtonClass)}
                          >
                            Deposit {token.shortName}
                          </Link>
                        </div>
                        <Link
                          to={"/#/mobile/positions/" + token.address + "/new"}
                          className={classNames("mt-5", primaryButtonClass)}
                        >
                          Already have {token.shortName}? Start earning!
                        </Link>
                      </div>
                    </div>
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
        ))}
        <div className="mt-4 mb-20">
          <div className="bg-[#202126] rounded-lg text-left py-5 px-7">
            <h1 className="text-lg text-[#D9D09D] font-bold">HOW TO STAKE</h1>
            <p className="mt-2 text-white">Learn how to earn high yields by staking your crypto.</p>
            <div className="mt-5 text-lg font-bold">
              <a 
                href="https://bomb.app/help"
                target="_blank"
                className="text-[#FAD02C] text-sm text-right" rel="noreferrer"
                onClick={(e) => {
                  // @ts-ignore
                  if (window.bombRouterType !== "browser") {
                    e.preventDefault();
                    setHowToOpen(true);
                  }
                }}
              >Learn More! <span className="text-[#DFCC75]">&gt;</span></a>
            </div>
          </div>
        </div>
        <div className="fixed bottom-0 w-10/12 lg:w-full lg:left-0 py-4">
          <Link
            to={"/#/mobile/refer"}
            className={classNames("lg:w-1/2 max-w-3xl lg:mx-auto", primaryButtonClass)}
          >
            Refer a friend to earn $50
          </Link>
        </div>
      </div>
    </div>
  );
}

export default TokenList;
