import React, { Fragment } from "react";
import logo from "../assets/images/logo.png";
import { primaryButtonClass } from "./Mobile/Positions/TokenList";
import { classNames } from "./Mobile/Positions/[token]/Components/DurationSelector";
import { Dialog, Transition } from "@headlessui/react";

const ConnectingModal = ({ web3Connecting }: { web3Connecting: boolean; }) => {
  return (
    <Transition.Root show={web3Connecting} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-5 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg
                bg-black w-full shadow-xl transition-all lg:w-1/2 max-w-3xl"
              >
                <div>
                  <div className="mt-3 mb-3 text-center text-white px-2 sm:mt-5">
                    <h1 className="text-lg font-bold">Connecting your wallet</h1>
                    <p className="mb-6">
                      {/* @ts-ignore */}
                      {window.bombRouterType === "browser" ?
                        "Please follow the prompts presented by your wallet." :
                        "Please wait while we connect your wallet."
                      }
                    </p>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

function ConnectWallet(
  // eslint-disable-next-line no-unused-vars
  { web3Connect, web3Connecting }: { web3Connect: (connector: any) => void, web3Connecting: boolean; }
) {
  return (
    <div className="App">
      <ConnectingModal web3Connecting={web3Connecting} />
      <div className="relative w-10/12 mx-auto lg:w-1/2 max-w-3xl">
        <div className="w-1/3 mx-auto">
          <img src={logo} alt="BOMB" />
        </div>
        <div className="text-lg font-bold">
          Welcome to the BOMB Money App
        </div>
        <hr className="mt-10" />
        <h1 className={"text-white text-xl mt-2"}>Please connect your wallet</h1>
        <button
          className={classNames("mt-3 w-full", primaryButtonClass)}
          onClick={() => { web3Connect("torus"); } }
        >
          Connect using Torus
        </button>
        <p className="mt-1">
          Use your existing Google, Facebook, Twitter or other accounts to create a wallet and connect.
        </p>

        <p className="mt-2">Alternatively connect using Metamask or WalletConnect</p>
        <p className="mt-2">
          <span
            className="text-black cursor-pointer bg-[#FAD02C] p-1 px-3 rounded-full"
            onClick={() => { web3Connect("injected"); }}
          >
            Metamask</span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span
            className="text-black cursor-pointer bg-[#FAD02C] p-1 px-3 rounded-full"
            onClick={() => { web3Connect("walletconnect"); }}
          >
            WalletConnect
          </span>
        </p>

        <hr className="mt-5" />

        <div className="text-lg mt-8">
          In order to interact with the BOMB Money Web3 app, you need to connect a wallet.
        </div>

        { /* @ts-ignore */ }
        {window.bombRouterType === "browser" && (
          <>
            <div className="text-lg mt-3">
              Learn how below:
            </div>

            <div className="lg:grid lg:grid-cols-2 lg:gap-x-5 mt-3">
              <div>
                <div className="mb-3">Create New Wallet</div>
                <iframe
                  style={{ width: "100%", height: "100%", background: "black" }}
                  src={"https://www.youtube.com/embed/B48QWdavbr0"}
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="How to download metamask"
                />
              </div>
              <div className="mt-5 lg:mt-0">
                <div className="mb-3">Import existing wallet</div>
                <iframe
                  style={{ width: "100%", height: "100%", background: "black" }}
                  src={"https://www.youtube.com/embed/qyysoepuvzs"}
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="How to purchase cryptocurrency"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ConnectWallet;
