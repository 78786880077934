import React, { useEffect } from "react";
import backArrow from "../../../../assets/images/ic_arrow_back.svg";
import { useNavigate, useParams } from "react-router-dom";
import { tokens } from "../TokenList";
import NetworkSelector from "./Components/NetworkSelector";
import QRCode from "react-qr-code";
import copyIcon from "../../../../assets/images/copy_icon.png";
import { BombApiContext } from "../../../../context/BombApiContext";
import { Web3BombappContext } from "../../../../context/Web3Bombapp";
import { notifyFailed, notifySuccess } from "../../../../components/Toast";

function DepositCoin() {
  const { address } = useParams();
  const navigate = useNavigate();
  const [network, setNetwork] = React.useState<any|null>();
  const [depositMinimumAmount, setDepositMinimumAmount] = React.useState<any|null>();
  const [depositWalletAddress, setDepositWalletAddress] = React.useState<string|null>(null);
  const { walletAddress } = React.useContext(Web3BombappContext);
  const { getDepositAddress, getDepositAsset } = React.useContext(BombApiContext);

  useEffect(() => {
    setDepositWalletAddress("");
    if (network) {
      getDepositAddress(network[2], walletAddress).then((response: any) => {
        if (response.error) {
          alert(response.error);
          return;
        }
        setDepositWalletAddress(response.depositAddress.address);
      });
      getDepositAsset(address).then((response: any) => {
        if (response.error) {
          alert(response.error);
          return;
        }
        setDepositMinimumAmount(response.depositAsset.minimum);
      });
    }
  }, [network]);

  const token = tokens[address];

  return (
    <div className="App">
      <div className="w-10/12 relative mx-auto lg:w-1/2 max-w-3xl">
        <button onClick={() => navigate(-1)} className="absolute left-2 top-11 text-2xl">
          <img src={backArrow} alt="Back" />
        </button>
        <div>
          <h1 className={"text-white text-2xl mt-10 mx-6 font-bold"}>Deposit {token.shortName} to your BOMB Wallet</h1>
          <div className="mt-10 text-2xl text-center">
            From which Network?
          </div>
          <div className="mx-3 mt-3">
            <NetworkSelector onChange={(value) => { setNetwork(value); }} networks={["ethereum", "binance"]} />
          </div>
        </div>
        {depositWalletAddress && (
          <>
            <div
              className="mt-10 mx-auto"
              style={{
                height: "auto",
                maxWidth: 150, width:
                "100%", background:
                "white", padding: "8px"
              }}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={depositWalletAddress}
                viewBox={"0 0 256 256"}
              />
            </div>
            <div className="mt-6 text-lg text-white">
              {network[1]} Wallet Address
            </div>
            <div className="relative mx-6">
              <input
                className="bg-white rounded-lg text-black text-xs py-3 text-center w-full px-4"
                readOnly
                value={depositWalletAddress}
              />
              <img
                src={copyIcon} className="w-8 h-8 absolute right-2 top-1 cursor-pointer"
                onClick={() => navigator.clipboard.writeText(depositWalletAddress).then(() => {
                  notifySuccess("Successfully copied");
                })
                  .catch(() => {
                    notifyFailed("Something went wrong");
                  })}
                alt="copy"
              />
            </div>
            <div className="text-left text-white mx-6 mt-3">
              <div className="font-bold text-sm pb-2">Use this address when making deposits of {token.shortName} to 
                BOMB App from the {network[1]} network. Only send supported assets shown through the deposit
                process.</div>
              <div className="font-semibold text-xs">
                You are depositing <span className="font-bold">{token.shortName}</span> on
                the <span className="font-bold">{network[1]}</span> network.
                Any tokens sent to a non-{network[1]} compatible address will be lost.</div>
              <div className="font-bold text-sm">
                Minimum deposit amount: <span className="text-lg">{depositMinimumAmount} {token.shortName}</span>
              </div>
            </div>
          </>
        )}
        <div className="mt-10">&nbsp;</div>
      </div>
    </div>
  );
}

export default DepositCoin;
