import React, { useEffect } from "react";
import { Web3BombappContext } from "../context/Web3Bombapp";
import useRefresh from "../hooks/useRefresh";

interface InputFromWalletProps {
  tokenAddress: string;
  defaultValue: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
}

export default function InputFromWallet({ tokenAddress, defaultValue, onChange }: InputFromWalletProps) {
  const [value, setValue] = React.useState(defaultValue);
  const { fastRefresh } = useRefresh();
  const [balance, setBalance] = React.useState(0);
  const { getBalance } = React.useContext(Web3BombappContext);

  useEffect(() => {
    getBalance(tokenAddress).then((balance: number) => {
      setBalance(balance);
    });
  }, [fastRefresh]);

  const handleChange = (event: any) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  const handleSetMax = () => {
    setValue(balance.toString());
    onChange(balance.toString());
  };

  return (
    <div className="relative">
      <input
        type="text"
        className="w-full border-b border-gray-500 px-5 py-3 text-black" placeholder="Enter Amount"
        value={value ?? ""}
        onChange={handleChange}
      />
      <div className="absolute right-3 top-3 text-[#bb861e] font-bold cursor-pointer" onClick={handleSetMax}>Max</div>
      <div className="float-right mt-2 mr-4"><span className="text-gray-500">Available:</span> {balance}BTC</div>
    </div>
  );
}
