import React, { useEffect, useState } from "react";
import backArrow from "../../../../assets/images/ic_arrow_back.svg";
import { useNavigate, useParams } from "react-router-dom";
import useRefresh from "../../../../hooks/useRefresh";
import { Web3BombappContext } from "../../../../context/Web3Bombapp";
import { BeefyContext } from "../../../../context/BeefyContext";
import { primaryButtonClass, tokens } from "../TokenList";
import { PositionType } from "../../../../../types/Position";
import moment from "moment";
import TransactionModal from "./Components/TransactionModal";
import { classNames } from "./Components/DurationSelector";

function Redeem() {
  const { address, tokenId } = useParams();
  const navigate = useNavigate();
  const { slowRefresh } = useRefresh();
  const { stakeInfo, withdraw } = React.useContext(Web3BombappContext);
  const { fetchPrice } = React.useContext(BeefyContext);

  if (!tokens[address]) {
    return <div>Invalid token</div>;
  }

  const [position, setPosition] = React.useState<PositionType | null>(null);
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    const decimals = 18;
    stakeInfo(tokens[address].stakeAddress, tokenId).then((position: any) => {
      const amount = Number(position.amountStaked) / Math.pow(10, decimals);
      setPosition({
        token: tokens[address],
        tokenId: Number(tokenId),
        amount: amount,
        allowWithdrawEarly: Boolean(position.allowWithdrawEarly),
        apr: Number(position.apr) / 100,
        created: Number(position.created),
        lockTime: Number(position.lockTime),
        dollarValue: fetchPrice ? fetchPrice({ id: tokens[address].beefySymbol }) * amount : null,
      });
    });
  }, [fetchPrice, slowRefresh]);

  if (!position) {
    return null;
  }

  return (
    <div className="App">
      <TransactionModal transaction={transaction} setTransaction={setTransaction} />
      <div className="relative w-10/12 mx-auto lg:w-1/2 max-w-3xl">
        <button onClick={() => navigate(-1)} className="absolute text-2xl left-2 top-11">
          <img src={backArrow} alt="Back" />
        </button>
        <h1 className={"text-white text-2xl mt-10 font-bold"}>Redeem Earlier</h1>
        <div>
          <div className="text-3xl font-bold text-green-300">${position.dollarValue.toFixed(2)}</div>
          <div className="text-3xl font-bold text-white">{position.amount} {tokens[address].shortName}</div>
        </div>
        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-500" />
        <div className="bg-[#b88917] text-black rounded-full p-2 font-bold mb-3">
          Redeeming early leads to a penalty of 50% of principal and forfeiting all rewards earned.
        </div>
        <div className="flex bg-[#202126] text-white font-bold rounded-full p-3 px-5 justify-items-center">
          <div className="text-left grow">
            Days until full redemption
          </div>
          <div className="text-right">
            {((position.created + position.lockTime - moment().unix()) / 86400).toFixed(0)} days
          </div>
        </div>
        <div className="flex bg-[#202126] text-white font-bold rounded-full p-3 px-5 justify-items-center mt-3">
          <div className="text-left grow">
            Amount Received
          </div>
          <div className="text-right">{position.amount / 2} {tokens[address].shortName}</div>
        </div>
        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-500" />
        <div className="mt-5">
          <button
            className={classNames("w-full", primaryButtonClass)}
            onClick={() => {
              (async() => {
                const tx = withdraw(tokens[address].stakeAddress, position.tokenId);
                setTransaction(tx);
              })();
            }}
          >Confirm Redeem</button>
        </div>
      </div>
    </div>
  );
}

export default Redeem;
