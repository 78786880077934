import React, { useEffect } from "react";
import { Web3BombappContext } from "./Web3Bombapp";

export const BombApiContext = React.createContext(null);

export const useLocalStorage = (storageKey: string, fallbackState: any) => {
  const [value, setValue] = React.useState(
    JSON.parse(localStorage.getItem(storageKey)) ?? fallbackState
  );

  React.useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};

// This context maintain 2 counters that can be used as a dependencies on other hooks to force a periodic refresh
export function BombApiContextProvider(props: any) {
  const baseUrl = "https://api.bombchain.com";
  const { walletAddress } = React.useContext(Web3BombappContext);
  const [userInfo, setUserInfo] = React.useState<any>(null);
  const [referredBy, setReferredBy] = useLocalStorage("referredBy", "null");

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    const getUserInfo = async (wallet: string, autoCreate: boolean) => {
      return await fetch(`${baseUrl}/user/${wallet}?referredBy=${referredBy}&autoCreate=` + (autoCreate ? "yes" : "no"),
        {
          method: "GET",
        }).then((response) => response.json());
    };
    getUserInfo(walletAddress, true).then((userInfo) => {
      setUserInfo(userInfo.user);
    });
  }, [walletAddress, referredBy]);

  return <BombApiContext.Provider
    value={{
      userInfo,
      referredBy,
      setReferredBy,
      getUserInfoByReferralCode: async (referralCode: string) => {
        return await fetch(`${baseUrl}/user/by-referral-code/${referralCode}`, {
          method: "GET",
        }).then((response) => response.json());
      },
      getDepositAsset: async (address: string) => {
        return await fetch(`${baseUrl}/deposit_asset/${address}`, {
          method: "GET",
        }).then((response) => response.json());
      },
      getDepositAddress: async (network: string, wallet: string) => {
        return await fetch(`${baseUrl}/address/${network}/${wallet}`, {
          method: "GET",
        }).then((response) => response.json());
      },
    }}
  >
    {props.children}
  </BombApiContext.Provider>;
}
