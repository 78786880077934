import React from "react";
import { tokens } from "../pages/Mobile/Positions/TokenList";

export default function TokenSymbol(props: any) {
  if (!props.address) {
    return <img src="https://via.placeholder.com/48" alt="No token address provided" />;
  }

  const token = tokens[props.address];

  if (!token) {
    return <img src="https://via.placeholder.com/48" alt="Invalid token" />;
  }

  return (
    <img
      src={token.image ? token.image : "https://swap.bomb.app/images/tokens/" + props.address + ".png"} 
      className={props.className} alt={token.shortName}
    />
  );
}
