import React, { useEffect } from "react";
import backArrow from "../../../../assets/images/ic_arrow_back.svg";
import TokenSymbol from "../../../../components/TokenSymbol";
import { useNavigate, useParams } from "react-router-dom";
import useRefresh from "../../../../hooks/useRefresh";
import { Web3BombappContext } from "../../../../context/Web3Bombapp";
import { BeefyContext } from "../../../../context/BeefyContext";
import { primaryButtonClass, tokens } from "../TokenList";
import { PositionType } from "../../../../../types/Position";
import moment from "moment";
import Link from "../../../../components/Link";

function Show() {
  const { address, tokenId } = useParams();
  const navigate = useNavigate();
  const { slowRefresh } = useRefresh();
  const { stakeInfo, withdraw } = React.useContext(Web3BombappContext);
  const { fetchPrice } = React.useContext(BeefyContext);

  if (!tokens[address]) {
    return <div>Invalid token</div>;
  }

  const [position, setPosition] = React.useState<PositionType | null>(null);

  useEffect(() => {
    const decimals = 18;
    stakeInfo(tokens[address].stakeAddress, tokenId).then((position: any) => {
      const amount = Number(position.amountStaked) / Math.pow(10, decimals);
      setPosition({
        token: tokens[address],
        tokenId: Number(tokenId),
        amount: amount,
        allowWithdrawEarly: Boolean(position.allowWithdrawEarly),
        apr: Number(position.apr) / 100,
        created: Number(position.created),
        lockTime: Number(position.lockTime),
        dollarValue: fetchPrice ? fetchPrice({ id: tokens[address].beefySymbol }) * amount : null,
      });
    });
  }, [fetchPrice, slowRefresh]);

  if (!position) {
    return null;
  }

  const isMature = moment().unix() > Number(position.created) + Number(position.lockTime);

  return (
    <div className="App">
      <div className="relative w-10/12 mx-auto lg:w-1/2 max-w-3xl">
        <button onClick={() => navigate(-1)} className="absolute text-2xl left-2 top-11">
          <img src={backArrow} alt="Back" />
        </button>
        <h1 className={"text-white text-2xl mt-10 font-bold"}>Staked</h1>
        <div className="flex items-center mt-10">
          <div className="w-1/3">
            <TokenSymbol address={address} className="inline w-12 h-12" />
            &nbsp; <span className="text-lg font-semibold">{tokens[address].shortName}</span>
          </div>
          <div className="w-2/3">
            <div className="text-3xl font-bold text-green-300">${position.dollarValue.toFixed(2)}</div>
          </div>
        </div>
        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-500" />
        <div className="flex bg-[#202126] text-white font-bold rounded-full p-3 px-5 justify-items-center">
          <div className="text-left grow">
            Amount Staked
          </div>
          <div className="text-right">{position.amount}{tokens[address].shortName}</div>
        </div>
        <div className="flex bg-[#202126] text-white font-bold rounded-full p-3 px-5 justify-items-center mt-3">
          <div className="text-left grow">
            Annual Yield
          </div>
          <div className="text-right">{position.apr}%</div>
        </div>
        <div className="flex bg-[#202126] text-white font-bold rounded-full p-3 px-5 justify-items-center mt-3">
          <div className="text-left grow">
            Maturity Date
          </div>
          <div className="text-right">{moment(position.created + position.lockTime, "X").format("lll")}</div>
        </div>
        {position.allowWithdrawEarly && (
          <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-500" />
        )}
        <div className="mt-5">
          {isMature ? (
            <div>
              <Link
                to={"/#/mobile/positions/" + position.token.address + "/" + position.tokenId + "/compound"}
                className={primaryButtonClass}
              >Compound</Link>
              <button
                className="block mt-5 bg-[#FAD02C] py-3 w-full rounded-full text-xl font-bold text-black"
                onClick={() => {
                  withdraw(tokens[address].stakeAddress, position.tokenId);
                }}
              >
                Redeem
              </button>
            </div>
          ) : position.allowWithdrawEarly && (
            <Link
              to={"/#/mobile/positions/" + position.token.address + "/" + position.tokenId + "/redeem"}
              className={primaryButtonClass}
            >Early Redeem</Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default Show;
