import React, { useEffect, useState } from "react";
import InputFromWallet from "../../../../components/InputFromWallet";
import DurationSelector, { classNames } from "./Components/DurationSelector";
import backArrow from "../../../../assets/images/ic_arrow_back.svg";
import TokenSymbol from "../../../../components/TokenSymbol";
import { useNavigate, useParams } from "react-router-dom";
import { Web3BombappContext } from "../../../../context/Web3Bombapp";
import useRefresh from "../../../../hooks/useRefresh";
import { primaryButtonClass, tokens } from "../TokenList";
import { BombApiContext } from "../../../../context/BombApiContext";
import TermsModal from "./Components/TermsModal";
import TransactionModal from "./Components/TransactionModal";

function New() {
  const { address } = useParams();
  const navigate = useNavigate();
  const { fastRefresh } = useRefresh();
  const [allowance, setAllowance] = React.useState<number>(0);
  const [acceptTerms, setAcceptTerms] = React.useState<boolean>(false);
  const [coupon, setCoupon] = React.useState<any>(null);
  const [eligibleForCoupon, setEligibleForCoupon] = React.useState<boolean>(false);
  const [showCouponField, setShowCouponField] = React.useState<boolean>(false);
  const { userInfo, getUserInfoByReferralCode } = React.useContext(BombApiContext);
  const {
    walletAddress,
    getAllowance,
    approveContract,
    createStake,
    createStakeWithReferral,
    hasReceivedBonus
  } = React.useContext(Web3BombappContext);
  const stakeContractAddress = tokens[address].stakeAddress;
  const spender = "0x18B4a766c032f4CE9BBEC495dAd9dc32D121C209";

  useEffect(() => {
    getAllowance(address, spender).then((allowance: number) => {
      setAllowance(allowance);
    });
    hasReceivedBonus(stakeContractAddress, walletAddress).then((hasReceivedBonus: boolean) => {
      setEligibleForCoupon(!hasReceivedBonus);
    });
  }, [fastRefresh]);

  const [amount, setAmount] = React.useState<string | null>();
  const [duration, setDuration] = React.useState<any | null>();

  const [termsOpen, setTermsOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [transaction, setTransaction] = useState(null);

  return (
    <div className="App">
      <TransactionModal transaction={transaction} setTransaction={setTransaction} />
      <TermsModal open={termsOpen} setOpen={setTermsOpen} url="https://bomb.app/terms" />
      <TermsModal open={privacyOpen} setOpen={setPrivacyOpen} url="https://bomb.app/privacy" />
      <div className="relative w-10/12 mx-auto lg:w-1/2 max-w-3xl">
        <button onClick={() => navigate(-1)} className="absolute text-2xl left-2 top-11">
          <img src={backArrow} alt="Back" />
        </button>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (!amount || !duration) {
              alert("Please select an amount and duration");
              return;
            }

            if (!acceptTerms) {
              alert("Please accept the terms and conditions before staking");
              return;
            }

            if (allowance <= 0) {
              (async() => {
                const tx = approveContract(address, spender);
                setTransaction(tx);
              })();
              return;
            }

            (async () => {
              const hasCoupon = coupon || (userInfo && userInfo.referredBy);
              if (eligibleForCoupon && hasCoupon) {
                let referrerInfo;
                if (coupon) {
                  referrerInfo = await getUserInfoByReferralCode(coupon);
                  if (referrerInfo.error) {
                    alert("Invalid referral code");
                    return;
                  }

                  if (referrerInfo.user.wallet === walletAddress) {
                    alert("You cannot use your own referral code");
                    return;
                  }
                } else if (userInfo && userInfo.referredBy) {
                  referrerInfo = { user: userInfo.referredBy };
                } else {
                  throw new Error("Invalid state");
                }

                const tx = createStakeWithReferral(
                  tokens[address].stakeAddress,
                  amount, duration.index,
                  referrerInfo.user.wallet
                );
                setTransaction(tx);
              } else {
                const tx = createStake(tokens[address].stakeAddress, amount, duration.index);
                setTransaction(tx);
              }
            })();
          }}
        >
          <h1 className={"text-white text-2xl mt-10 font-bold"}>Create Stake</h1>
          <div className="flex items-center mt-10">
            <div className="w-1/3">
              <TokenSymbol address={address} className="inline w-12 h-12" />
              &nbsp; <span className="text-lg font-semibold">BTC</span>
            </div>
            <div className="w-2/3">
              <div className="text-sm font-semibold text-gray-500">ANNUALIZED INTEREST RATE</div>
              <div className="text-3xl font-bold text-green-300">{duration ? duration[0] / 100 : ""}%</div>
            </div>
          </div>
          <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-500" />
          <div className="flex bg-[#bb861e] text-black font-bold rounded-full p-3 justify-items-center">
            <div className="grow">
              Minimum Staking Amount
            </div>
            <div className="text-xl text-right">0.0001BTC</div>
          </div>
          {eligibleForCoupon && userInfo && userInfo.referredBy && (
            <div className="mt-8 text-[#FAD02C]">
              <div>Great, looks like you have been referred!</div>
              <div>Deposit $100 to get your free position!</div>
            </div>
          )}
          <div className="mt-5">
            <div className="mb-1 ml-5 text-left text-gray-400">Staking Amount</div>
            <InputFromWallet tokenAddress={address} defaultValue={amount} onChange={(value) => { setAmount(value); }} />
          </div>
          <div className="mt-8">
            <div className="mb-1 ml-5 text-left text-gray-400">Duration</div>
            <DurationSelector onChange={(value) => { setDuration(value); }} />
          </div>
          {eligibleForCoupon && (!userInfo || !userInfo.referredBy) && (
            <>
              {!showCouponField ? (
                <div className="mt-8 text-[#FAD02C] text-sm text-right">
                  <a
                    href={undefined}
                    onClick={() => setShowCouponField(true)}
                    className="block cursor-pointer"
                  >Have a referral code?</a>
                </div>
              ) : (
                <div className="mt-8">
                  <div className="mb-1 ml-5 text-left text-gray-400">Referral code</div>
                  <input
                    type="text"
                    className="w-full px-5 py-3 text-black border-b border-gray-500" placeholder="Enter referral code"
                    value={coupon ?? ""}
                    onChange={(e) => setCoupon(e.target.value)}
                  />
                </div>
              )}
            </>
          )}
          <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-500" />
          <div>
            <div className="flex justify-center">
              <input
                className="form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-[#FAD02C]
                    checked:border-yellow-600 focus:outline-none transition duration-200 mt-1 align-top
                    bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer text-white accent-[#FAD02C]"
                type="checkbox"
                value=""
                id="acceptStakeTerms"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
              />
              <label 
                className="inline-block text-white form-check-label" 
                style={{fontSize:"13px"}} 
                htmlFor="acceptStakeTerms"
              >
                I have read and agree to the
                <a 
                  href="https://bomb.app/terms"
                  target="_blank"
                  className="text-[#FAD02C] text-sm text-right" rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    setTermsOpen(true);
                  }}
                > terms of service
                </a> & <a 
                  href="https://bomb.app/privacy"
                  target="_blank"
                  className="text-[#FAD02C] text-sm text-right" rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    setPrivacyOpen(true);
                  }}
                > privacy policy
                </a>
              </label>
            </div>
          </div>
          <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-500" />
          <div className="mt-5">
            <button className={classNames("w-full", primaryButtonClass)}>
              {allowance > 0 ? "Stake Now" : "Approve"}
            </button>
          </div>
        </form>
        <div className="mt-10">&nbsp;</div>
      </div>
    </div>
  );
}

export default New;
