import React from "react";
import logo from "../assets/images/logo.png";

function Loading({ type }: { type: string }) {

  return (
    <div className="App">
      <div className="relative w-10/12 mx-auto lg:w-1/2 max-w-3xl">
        <div className="w-1/3 mx-auto">
          <img src={logo} alt="BOMB" />
        </div>
        <div className="text-lg font-bold">
          Welcome to the BOMB Money App
        </div>
        <hr className="mt-10" />
        <h1 className={"text-white text-xl mt-5"}>{ type }</h1>

        <hr className="mt-5" />

        <div className="text-lg mt-8">
          Please wait...
        </div>
      </div>
    </div>
  );
}

export default Loading;
