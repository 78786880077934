import React, { useEffect } from "react";
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { Web3BombappContext } from "../../../../../context/Web3Bombapp";

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface DurationSelectorProps {
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => void;
}

export default function DurationSelector({ onChange }: DurationSelectorProps) {
  const [selected, setSelected] = useState();
  const { getAllLockOptions } = React.useContext(Web3BombappContext);
  const [durations, setDurations] = useState([]);

  useEffect(() => {
    const BTCSTAKEaddress = "0x886a4003A7A7279A3Ab342E4BB2F11153b7318a3";
    getAllLockOptions(BTCSTAKEaddress).then((lockOptions: any[]) => {
      const mappedLockOptions = lockOptions.map((lockOption, index) => {
        return { ...lockOption, index };
      });
      setDurations(mappedLockOptions);
      setSelected(mappedLockOptions[mappedLockOptions.length - 1]);
    });
  }, []);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  if (durations.length === 0) {
    return null;
  }

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className="sr-only"> Duration </RadioGroup.Label>
      <div className="flex space-x-4">
        {durations.map((duration) => (
          <RadioGroup.Option
            key={duration[1]}
            value={duration}
            className={({ checked, active }) =>
              classNames(
                active || checked ? "bg-[#FED700]" : "bg-[#202126]",
                "relative block cursor-pointer rounded-full p-3 shadow-sm focus:outline-none justify-between grow"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    active || checked ? "text-[#000]" : "text-gray-300",
                    "flex flex-col text-sm text-center w-full")}
                >
                  <RadioGroup.Label as="span" className="font-medium">
                    {(duration[1] / 86400).toFixed(0) + " days"}
                  </RadioGroup.Label>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
